<template>
  <b-form-input
    v-model="valueCopy"
    :state="state"
    :type="type"
    :placeholder="placeholder"
    :readonly="readonly"
    :disabled="disabled"
    :autocomplete="autocomplete"
    @keydown="keydownEvent"
    @keyup="keyupEvent"
  ></b-form-input>
</template>
<script>
import audioLevel from "../constants/audioConstants";
import playSound from "../services/AudioService";
export default {
  name: "PnwSelect",
  props: {
    state: {
      default: null,
    },
    value: {
      required: true,
    },
    placeholder: {
      default: "",
    },
    type: {
      default: "text",
    },
    readonly: {
      default: false,
    },
    disabled: {
      default: false,
    },
    autocomplete: {
      default: "",
    },
  },
  methods: {
    keydownEvent(e) {
      playSound("GF-PN1-Typing_1");
      this.$emit("keydown", e);
    },
    keyupEvent(e) {
      this.$emit("keyup", e);
    },
  },
  computed: {
    valueCopy: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.$emit("changed", value);
      },
    },
  },
};
</script>
