import nameLookupTable from "../constants/nameLookupTable";

export default function generateName(selections = 0) {
  const levels = [0, 1, 4, 8, 11, 12];
  let level = 0;
  let i = 0;
  for (i = 0; i < levels.length - 1; i++) {
    if (levels[i] <= selections && selections < levels[i + 1]) {
      level = i;
      break;
    }
  }

  const randomIdx =
    Math.round(Math.random() * 1000) % nameLookupTable[level].length;

  return nameLookupTable[level][randomIdx];
}
