<template>
  <b-alert show variant="danger" class="mb-5">
    <div class="pre-error--icon">
      <img class="pre-error--src" src="/assets/images/warning.png" alt="" />
    </div>
    <div class="pre-error--copy">
      <div>
        <slot> </slot>
      </div>
    </div>
  </b-alert>
</template>
<script>
export default {
  name: "ErrorAlert",
};
</script>
