const nameLookupTable = [
  [
    "Astral Apprentice",
    "Astral Scholar",
    "Astral Acolyte",
    "Sumerian Scholar",
    "Sumerian Acolyte",
    "Spectral Apprentice",
    "Spectral Scholar",
    "Spectral Acolyte",
    "Spirit Apprentice",
    "Spirit Scholar",
    "Spirit Acolyte",
    "Occult Apprentice",
    "Occult Scholar",
    "Occult Acolyte",
    "Gozerian Apprentice",
    "Gozerian Scholar",
    "Gozerian Acolyte",
  ],
  [
    "Astral Scribe",
    "Astral Analyst",
    "Astral Auditor",
    "Sumerian Scribe",
    "Sumerian Analyst",
    "Sumerian Auditor",
    "Spectral Scribe",
    "Spectral Analyst",
    "Spectral Auditor",
    "Spirit Scribe",
    "Spirit Analyst",
    "Occult Scribe",
    "Occult Analyst",
    "Gozerian Scribe",
    "Gozerian Analyst",
  ],
  [
    "Astral Agent",
    "Astral Seeker",
    "Astal Librarian",
    "Sumerian Agent",
    "Sumerian Seeker",
    "Sumerian Librarian",
    "Spectral  Agent",
    "Spectral Seeker",
    "Spectral Librarian",
    "Spirit Agent",
    "Spirit Seeker",
    "Spirit Librarian",
    "Occult Agent",
    "Occult Seeker",
    "Occult Librarian",
    "Gozerian Agent",
    "Gozerian Seeker",
    "Gozerian Librarian",
  ],
  [
    "Astral Archivist",
    "Astral Specialist",
    "Astral Medium",
    "Sumerian Archivist",
    "Sumerian Specialist",
    "Sumerian Medium",
    "Spectral Archivist",
    "Spectral Specialist",
    "Spectral Medium",
    "Spirit Archivist",
    "Spirit Specialist",
    "Spirit Medium",
    "Occult Archivist",
    "Occult Specialist",
    "Occult Medium",
    "Gozerian Archivist",
    "Gozerian Specialist",
    "Gozerian Medium",
  ],
  [
    "Astral Sage",
    "Astral Prophet",
    "Sumerian Sage",
    "Sumerian Prophet",
    "Spectral  Sage",
    "Spectral Prophet",
    "Spectral  Sage",
    "Spectral Prophet",
    "Spirit Auditor",
    "Spirit Sage",
    "Occult Auditor",
    "Occult Sage",
    "Gozerian Auditor",
    "Gozerian Sage",
  ],
];

export default nameLookupTable;
